// eslint-disable no-console no-unused-vars

// import './components/nav-menu'
const mobileBreackpoint = 992

var isMobile = window.innerWidth < mobileBreackpoint

function setCookie(cname, cvalue, exdays) {
  const d = new Date()
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000)
  let expires = 'expires=' + d.toUTCString()
  document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/'
}

function getCookie(cname) {
  let name = cname + '='
  let decodedCookie = decodeURIComponent(document.cookie)
  let ca = decodedCookie.split(';')
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i]
    while (c.charAt(0) == ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(name) == 0) {
      let result = c.substring(name.length, c.length)
      if (result) {
        return JSON.parse(result)
      }
      // return c.substring(name.length, c.length);
    }
  }
  return ''
}

function addAppearingClassToElement(selector) {
  const el = document.querySelector(selector)
  const isMobile = window.innerWidth < mobileBreackpoint
  if (!el || isMobile) {
    return
  }
  const observer = new window.IntersectionObserver(
    ([entry]) => {
      if (entry.isIntersecting) {
        el.classList.add('appearing')
        return
      }
    },
    {
      root: null,
      threshold: 0.1 // set offset 0.1 means trigger if atleast 10% of element in viewport
    }
  )

  observer.observe(el)
}

function toggleErrorToField(name, errorText) {
  const wrapper = document.getElementsByName(name)[0]?.parentElement

  if (!wrapper) {
    return
  }

  if (name == 'agreement') {
    if (errorText) {
      wrapper.classList.add('error')
    } else {
      wrapper.classList.remove('error')
    }
    return
  }

  if (errorText) {
    wrapper.classList.add('error')
    wrapper.querySelector('.text-error').textContent = errorText
  } else {
    wrapper.classList.remove('error')
    wrapper.querySelector('.text-error').textContent = ''
  }
}

const isVideoPlaying = video =>
  !!(video.currentTime > 0 && !video.paused && !video.ended && video.readyState > 2)
function checkIsVideoPlaying() {
  const video = document.querySelector('.hero-banner video')
  if (!video) return

  video.ontimeupdate = function () {
    if (isVideoPlaying(video) && document.querySelector('.play-btn')) {
      document.querySelector('.play-btn').remove()
    }
  }

  setTimeout(() => {
    if (!isVideoPlaying(video)) {
      document.querySelectorAll('.play-btn').forEach(btn => {
        btn.classList.add('show')
        btn.addEventListener('click', () => {
          video.play()
          btn.classList.remove('show')
        })
      })

      // .classList.add('show')
      // document.querySelector('.play-btn').addEventListener('click', () => {
      //   video.play()
      //   document.querySelector('.play-btn').classList.remove('show')
      // })
    }
  }, 1000)
}

document.addEventListener('DOMContentLoaded', () => {
  checkIsVideoPlaying()

  addAppearingClassToElement('.investment-wrapper.bottom-content')

  // Cookies
  let cookies = getCookie('CookiesConsent')

  if (!cookies?.consent) {
    document.querySelector('.cookies-banner-overlay').classList.add('show')

    document.querySelector('.cookies-banner .close-btn').addEventListener('click', () => {
      document.querySelector('.cookies-banner-overlay').classList.add('dissappearing')
      setTimeout(() => {
        document.querySelector('.cookies-banner-overlay').classList.remove('show')
      }, 500)

      setCookie('CookiesConsent', JSON.stringify({ consent: true }), 365)
    })
  }

  // Contact form
  const contactForm = document.getElementById('contact_form')

  if (contactForm) {
    // Change option selected
    const label = document.querySelector('.dropdown__filter-selected')
    const options = Array.from(document.querySelectorAll('.dropdown__select-option'))

    contactForm.querySelectorAll('input').forEach(input => {
      input.addEventListener('input', () => {
        toggleErrorToField(input.name, null)
      })
    })

    options.forEach(option => {
      option.addEventListener('click', () => {
        label.textContent = option.textContent
        document
          .querySelector('#reason')
          .querySelector(`option[value="${option.dataset.name}"]`).selected = true
        toggleErrorToField('reason', null)
      })
    })

    // Close dropdown onclick outside
    document.addEventListener('click', e => {
      const toggle = document.querySelector('.dropdown__switch')
      const element = e.target

      if (element == toggle) return

      const isDropdownChild = element.closest('.dropdown__filter')

      if (!isDropdownChild) {
        toggle.checked = false
      }
    })

    const selectReason = document.querySelector('#reason')

    selectReason.addEventListener('change', e => {
      const selected = e.target.selectedOptions[0].value
      label.textContent = selected
      toggleErrorToField('reason', null)
    })

    // Validation texarea

    document.getElementById('message').addEventListener('input', e => {
      const value = e.target.value
      const error =
        (!value && 'Field empty') ||
        (value.length > 100 && `Too many symbols. ${value.length} out of 100`) ||
        null

      toggleErrorToField('message', error)
    })

    // Agreement

    const agreement = contactForm.querySelector('[value="policy"]')
    agreement.addEventListener('change', e => {
      const checked = e.target.checked
      if (!checked) {
        document.querySelector('#send_form').disabled = true
      } else {
        document.querySelector('#send_form').disabled = false
      }
    })

    contactForm.addEventListener('submit', e => {
      e.preventDefault()

      const validation = {
        firstname: null,
        lastname: null,
        agreement: null,
        email: null,
        reason: null,
        message: null
      }

      const formData = new FormData(contactForm)
      const data = Object.fromEntries(formData.entries())

      validation.firstname =
        (!data.firstname && 'Field empty') ||
        (!/^[a-zA-Z][0-9a-zA-Z .,'-]*$/.test(data.firstname) && 'Wrong name format') ||
        null

      validation.lastname =
        (!data.lastname && 'Field empty') ||
        (!/^[a-zA-Z][0-9a-zA-Z .,'-]*$/.test(data.lastname) && 'Wrong name format') ||
        null

      validation.email =
        (!data.email && 'Field empty') ||
        // eslint-disable-next-line no-useless-escape
        (!/^[\w\.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(data.email) && 'Wrong email format') ||
        null

      validation.message =
        (!data.message && 'Field empty') ||
        (data.message.length > 100 && `Too many symbols. ${data.message.length} out of 100`) ||
        null

      validation.agreement = contactForm.querySelector('[value="policy"]').checked
        ? null
        : 'Required'

      validation.reason = data.reason ? null : 'Field empty'

      toggleErrorToField('firstname', validation.firstname)
      toggleErrorToField('lastname', validation.lastname)
      toggleErrorToField('agreement', validation.agreement)
      toggleErrorToField('email', validation.email)
      toggleErrorToField('reason', validation.reason)
      toggleErrorToField('message', validation.message)

      if (
        !validation.firstname &&
        !validation.lastname &&
        !validation.agreement &&
        !validation.email &&
        !validation.reason &&
        !validation.message
      ) {
        window.location.href = '/success.html'
      }
    })
  }

  // Mobile menu
  const mobileMenuButtons = document.querySelectorAll('.menuToggle input')
  // const mobileMenuButtonClose = document.querySelector('.menuToggle.close input')

  mobileMenuButtons.forEach(button => {
    button.addEventListener('change', e => {
      const isMenuVisible = e.target.checked
      document.querySelector('.menuToggle.close input').checked = e.target.checked
      document.querySelector('.menuToggle.open input').checked = e.target.checked
      document.body.classList.toggle('mobile-menu-open', isMenuVisible)
    })
  })
  // mobileMenuButton.addEventListener('change', e => {
  //   document.body.classList.toggle('mobile-menu-open', true)
  // })
  // mobileMenuButtonClose.addEventListener('change', e => {
  //   document.body.classList.toggle('mobile-menu-open', false)
  // })

  window.addEventListener('resize', () => {
    isMobile = window.innerWidth < mobileBreackpoint

    if (!isMobile) {
      // document.querySelectorAll('.menuToggle input').checked = false
      document.querySelectorAll('.menuToggle input').forEach(menuToggle => {
        menuToggle.checked = false
      })
      document.body.classList.toggle('mobile-menu-open', false)
    }
  })
})
